///////////////////////////////////////////////////////////
//
//   jQueryインポート用JS
//
///////////////////////////////////////////////////////////

//---------------------------------------------------------
// 必要ファイルインポート
//---------------------------------------------------------
import jquery from "jquery";
window.jQuery = jquery;
window.$ = jquery;
